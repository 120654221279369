/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyAcMea14rAFNVibx0eEo1qp1ThgbmFJXGI",
  "appId": "1:448836221061:web:591d2b0b86b93d2d5711ec",
  "authDomain": "schooldog-i-emanuel-ga.firebaseapp.com",
  "measurementId": "G-3CVWS55N1S",
  "messagingSenderId": "448836221061",
  "project": "schooldog-i-emanuel-ga",
  "projectId": "schooldog-i-emanuel-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-emanuel-ga.appspot.com"
}
